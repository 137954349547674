<template>
  <v-btn x-large color="success" @click="pagar()" :loading="!paymentCheckout">
    <v-icon left>mdi-credit-card-outline</v-icon>
    Pagar con Tarjeta
  </v-btn>
</template>

<script>
import PaymentezService from '@/api/paymentez/PaymentezService';
import Vue from 'vue';
export default Vue.extend({
  //
  //props: [""],
  props: {
    userID: {
      type: Number,
      default: 0
    },
    description: String,
    reference: String,
    username: String,
    amount: Number,
    // optional
    vat: {
      type: Number,
      default: 0
    },
    taxPercentage: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      paymentCheckout: undefined,
      //
      paymentezEnvMode: "stg",
      paymentezClientAppCode: "TESTECUADORSTG-EC-SERVER",
      paymentezClientAppKey: "67vVmLALRrbSaQHiEer40gjb49peos"
    };
  },
  methods: {
    openPaymentezModal(reference) {
      this.paymentCheckout.open({
        reference: reference
      });
    },
    pagar() {
      console.log(`vat: ${this.vat} , taxPercentage: ${this.taxPercentage}`);
      PaymentezService.initReference({
        user: {
          id: `${this.userID}`,
          email: this.username,
        },
        order: {
          description: this.description,
          amount: this.amount,
          vat: this.vat,          
          devReference: `${this.reference}`,
          taxableAmount: 0,
          taxPercentage: this.taxPercentage
        },
        locale: "es"
      }).then((response) => {
        console.log(response);
        this.openPaymentezModal(response.reference);
      }).catch((error) => {
        this.$swal({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
            title: "Paymentez",
            text: "Error al cargar pagina"
          });
      });
    }
  },
  mounted() {
    //foo();
    //
    //var self = this;
    console.log("iniciando el plugin");
    const externalScript = document.createElement("script");
    // eslint-disable-next-line
    const self = this;
    externalScript.onload = () => {
      console.log("recien cargando");
      
      // eslint-disable-next-line
      this.paymentCheckout = new PaymentCheckout.modal({
        // eslint-disable-next-line
        env_mode: this.paymentezEnvMode, // `prod`, `stg`, `local` to change environment. Default is `stg`
        onOpen: function() {
          console.log("modal open");
        },
        onClose: function() {
          console.log("modal closed");
        },
        onResponse: function(response) {
          console.log(response);
          if (
            response &&
            response.transaction &&
            response.transaction.status
            // failure vs success or pending
            //response.transaction.status === "success"
          ) {
            self.$emit("onResponse", response);
          }
        }
      });
      console.log("si cargo");
    };

    //https://cdn.paymentez.com/ccapi/sdk/payment_checkout_stable.min.js
    // ANTIGUO LINK 

    externalScript.setAttribute(
      "src",
      "https://cdn.paymentez.com/ccapi/sdk/payment_checkout_3.0.0.min.js"
    );
    document.head.appendChild(externalScript);
  }
});
</script>
